import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import axios from 'axios';

import { Menu, X } from 'react-feather';

import { NavbarLink } from './Components/NavbarLink';
import { SlidingText } from './Components/SlidingText';
import { ServiceBlock } from './Components/ServiceBlock';
import FormTextInput from './Components/FormTextInput';
import FormTextArea from './Components/FormTextArea';
import AnimatedFormButton from './Components/AnimatedFormButton';

import estudio from './assets/estudio.png';
import rocca from './assets/rocca.png';

import consumer from './assets/consumer.png';
import insurance from './assets/insurance.png';
import art from './assets/art.png';

import anima from './assets/animaLogo.png';

import './App.css';

function randy(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const headerImgSrc = [
  {
    webp: './assets/azul2.webp',
    png: './assets/azul2.png'
  },
  {
    webp: './assets/campoFinal.webp',
    png: './assets/campoFinal.png'
  },
  {
    webp: './assets/quequenFinal.webp',
    png: './assets/quequenFinal.png'
  },
  {
    webp: './assets/tandilFinal.webp',
    png: './assets/tandilFinal.png'
  },
  {
    webp: './assets/mdpFinal.webp',
    png: './assets/mdpFinal.png'
  },
];

const mobileImgSrc = headerImgSrc[randy(0,4)].png;

const App = () => {

  const [headerImg, setHeaderImg] = useState(null);

  const [windowSize, setWindowSize] = useState({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth});
  const [isMobile, setIsMobile] = useState(window.document.documentElement.clientWidth <= 1024);

  const [webP, setWebP] = useState(null);

  useEffect(() => {  
    setWindowSize({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth});
  
    if (window.document.documentElement.clientWidth <= 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    const checkForWebP = async () => {
      if (!window.self.createImageBitmap) return false;
      
      const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
      const blob = await fetch(webpData).then(r => r.blob());
      const supportsWebP = await createImageBitmap(blob).then(() => true, () => false);
      
      setWebP(supportsWebP ? 'webp' : 'png');

      if (supportsWebP) {
        setHeaderImg(headerImgSrc[randy(0,4)].webp);
      } else {
        setHeaderImg(headerImgSrc[randy(0,4)].png);
      }
    }

    checkForWebP();
  }, []);

  useEffect(() => {
    const onResize = () => {
      setWindowSize({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth});
    
      if (window.document.documentElement.clientWidth <= 1024) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  const [logoStage, setLogoStage] = useState(1);
  const [navbarStage, setNavbarStage] = useState(2);

  const [openMenu, setOpenMenu] = useState(false);

  const menuProps = useSpring({ opacity: openMenu ? 1 : 0, pointerEvents: openMenu ? 'all' : 'none' });

  const imagesLoaded = [false, false, false, false];

  const imageLoaded = (number) => {
    imagesLoaded[number] = true;

    if (imagesLoaded.every(value => value)) {
      setLogoStage(2);
    }
  }

  const logoContainerProps = useSpring(logoStage === 1 
    ? { top: 'calc(50vh)', left: 'calc(50vw)', transform: 'translate(-50%, calc(-19vh))', cursor: 'default', pointerEvents: 'none' } 
    : logoStage === 2 
      ? { top: !isMobile ? 'calc(70vh)' : 'calc(65vh)', left: 'calc(6vw)', transform: !isMobile ? 'translate(0%, calc(-15vh))' : 'translate(0%, calc(-6vh))', cursor: 'default', pointerEvents: 'none' } 
      : { top: !isMobile ? 'calc(7.5vh)' : windowSize.width > 767 ? 'calc(4vh)' : 'calc(5vh)', left: 'calc(6vw)', transform: !isMobile ? 'translate(0%, calc(-2vh))' : 'translate(0%, calc(-1vh))', cursor: 'pointer', pointerEvents: 'all' }
  );

  const firstLogoProps = useSpring(logoStage === 1 
    ? { top: 0, left: 0, height: !isMobile ? 'calc(16vh)' : 'calc(8vh)' } 
    : logoStage === 2 
      ? { top: 0, left: 0, height: !isMobile ? 'calc(8vh)' : 'calc(4vh)' } 
      : { top: 0, left: 0, height: !isMobile ? 'calc(4vh)' : 'calc(2vh)' }
  );
  
  const secondLogoProps = useSpring(logoStage === 1 
    ? { top: '102%', left: '0%', height: !isMobile ? 'calc(20vh)' : 'calc(10vh)' } 
    : logoStage === 2 
      ? { top: '102%', left: '0%', height: !isMobile ? 'calc(10vh)' : 'calc(5vh)' } 
      : { top: '0%', left: '103%', height: !isMobile ? 'calc(4vh)' : 'calc(2vh)' }
  );

  const [{ y }, setY] = useSpring(() => ({ y: 0 }))

  const layoutRef = useRef();

  const onScroll = e => {

    if (windowSize.width > 1024) {
      if (e.target.scrollTop < 20) {
        setLogoStage(2);
      } else if (e.target.scrollTop < 200) {
        setNavbarStage(2);
        setLogoStage(3);
      } else if (e.target.scrollTop < 400) {
        setNavbarStage(3);
      } else if (e.target.scrollTop < 1050) {
        setSecondLine(true);
      } else if (e.target.scrollTop < 1780) {
        setFirstFeature(true);
        setSecondLine(false);
        setFirstLine(true);
      } else if (e.target.scrollTop < 2500) {
        setSecondLine(true);
        setFirstLine(false);
        setSecondFeature(true);
      } else if (e.target.scrollTop < 3550) {
        setSecondLine(false);
        setFirstLine(true);
        setThirdFeature(true);
      } else {
        setContactSliding(true);
      }
    } else if (windowSize.width > 767) {
      if (e.target.scrollTop < 20) {
        setLogoStage(2);
      } else if (e.target.scrollTop < 200) {
        setNavbarStage(2);
        setLogoStage(3);
      } else if (e.target.scrollTop < 400) {
        setNavbarStage(3);
      } else if (e.target.scrollTop < 840) {
        setSecondLine(true);
      } else if (e.target.scrollTop < 1250) {
        setFirstFeature(true);
        setSecondLine(false);
        setFirstLine(true);
      } else if (e.target.scrollTop < 1700) {
        setSecondLine(true);
        setFirstLine(false);
        setSecondFeature(true);
      } else if (e.target.scrollTop < 2110) {
        setSecondLine(false);
        setFirstLine(true);
        setThirdFeature(true);
      } else {
        setContactSliding(true);
      }
    } else {
      if (e.target.scrollTop < 20) {
        setLogoStage(2);
      } else if (e.target.scrollTop < 200) {
        setNavbarStage(2);
        setLogoStage(3);
      } else if (e.target.scrollTop < 400) {
        setNavbarStage(3);
      }
    }

  }

  const [firstFeature, setFirstFeature] = useState(windowSize.width < 768 ? true : false);
  const [secondFeature, setSecondFeature] = useState(windowSize.width < 768 ? true : false);
  const [thirdFeature, setThirdFeature] = useState(windowSize.width < 768 ? true : false);
  const [firstLine, setFirstLine] = useState(true);
  const [secondLine, setSecondLine] = useState(true);
  const [contactSliding, setContactSliding] = useState(windowSize.width < 768 ? true : false);

  const layoutProps = useSpring({ opacity: logoStage === 1 ? 0 : 1 });

  const firstLineProps = useSpring({ height: logoStage > 1 ? windowSize.height : 0, opacity: firstLine ? 1 : 0, config: config.molasses });
  const secondLineProps = useSpring({ height: logoStage > 1 ? windowSize.height : 0, opacity: secondLine ? 1 : 0, config: config.molasses });

  const firstFeatureImgProps = useSpring({ transform: firstFeature ? windowSize.width < 768 ? 'translate(0,0)' : 'translate(-50%, -50%)' : 'translate(100%, -50%)', config: config.slow });
  const secondFeatureImgProps = useSpring({ transform: secondFeature ? windowSize.width < 768 ? 'translate(0,0)' : 'translate(-50%, -50%)' : 'translate(-150%, -50%)', config: config.slow });
  const thirdFeatureImgProps = useSpring({ transform: thirdFeature ? windowSize.width < 768 ? 'translate(0,0)' : 'translate(-50%, -50%)' : 'translate(100%, -50%)', config: config.slow });

  const fixedOverlayBgProps = useSpring({ backgroundColor: logoStage > 2 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)' });

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [buttonText, setButtonText] = useState('Enviar');

  const handleSubmit = () => {
    if (buttonText === 'Enviado!' || buttonText === 'Error') {
      setButtonText("Enviar");
      setName("");
      setEmail("");
      setMessage("");
    } else {
      setButtonText("Enviando...");

      axios({
        method:'post',
        url: "https://us-central1-new-functions-rocca.cloudfunctions.net/sendSupportEmail",
        data: {
          name: name,
          email: email,
          message: message
        }
        })
          .then((response) => {
            setButtonText("Enviado!");
          })
          .catch((error) => {
            console.log(error);
            setButtonText("Error");
          });
    }
  }

  const scrollTo = (scrollPosition) => {
    setY({
      y: scrollPosition,
      reset: true,
      from: { y: layoutRef.current.scrollTop },
      onFrame: props => layoutRef.current.scroll(0, props.y)
    })
  }

  const clickMenuMobile = (scrollPosition) => {
    setOpenMenu(false);

    setY({
      y: scrollPosition,
      reset: true,
      from: { y: layoutRef.current.scrollTop },
      onFrame: props => layoutRef.current.scroll(0, props.y)
    });
  }

  return (
    <div className="app">
      <animated.div style={firstLineProps} className="firstLine" />
      <animated.div style={secondLineProps} className="secondLine" />
      <animated.div style={fixedOverlayBgProps} className="fixedOverlay">
        { openMenu
          ? <X color={"#fff"} size={32} className="navMenuIcon" onClick={() => setOpenMenu(false)} />
          : <Menu color={logoStage === 3 ? "#333" : "#fff"} size={32} className="navMenuIcon" onClick={() => setOpenMenu(true)} />
        }
        <animated.div style={menuProps} className="mobileMenu">
          <p className="mobileMenuItem" onClick={() => clickMenuMobile(windowSize.width < 768 ? 400 : 850)}>Áreas de práctica</p>
          <p className="mobileMenuItem" onClick={() => clickMenuMobile(windowSize.width < 768 ? 1300 : 1330)}>Cobertura</p>
          <p className="mobileMenuItem" onClick={() => clickMenuMobile(windowSize.width < 768 ? 2030 : 1760)}>Único interlocutor</p>
          <p className="mobileMenuItem" onClick={() => clickMenuMobile(windowSize.width < 768 ? 2540 : 2190)}>Amplia experiencia</p>
          <p className="mobileMenuItem" onClick={() => clickMenuMobile(windowSize.width < 768 ? 3140 : 2600)}>Contacto</p>
        </animated.div>
        <animated.div style={layoutProps} className="navbar">
          <NavbarLink text="Áreas de práctica" stage={navbarStage} onClick={() => scrollTo(670)} />
          <NavbarLink text="Cobertura" stage={navbarStage} onClick={() => scrollTo(1420)} />
          <NavbarLink text="Único interlocutor" stage={navbarStage} onClick={() => scrollTo(2200)} />
          <NavbarLink text="Amplia experiencia" stage={navbarStage} onClick={() => scrollTo(3000)} />
          <NavbarLink text="Contacto" stage={navbarStage} onClick={() => scrollTo(3800)} />
        </animated.div>
        <animated.div className="logoContainer" style={logoContainerProps} onClick={() => scrollTo(0)}>
          <animated.img src={estudio} alt="ESTUDIO" className="firstLogo" style={firstLogoProps} />
          <animated.img src={rocca} alt="ROCCA" className="secondLogo" style={secondLogoProps} />
        </animated.div>
      </animated.div>
      <animated.div scrollTop={y.to(value => value)} className="layout" ref={layoutRef} onScroll={onScroll} style={{ width: windowSize.width, height: windowSize.height }}>
        <animated.img style={layoutProps} onLoad={() => imageLoaded(0)} src={isMobile ? require('./assets/path6.png') : !webP ? null : webP === 'webp' ? require('./assets/path6.webp') : require('./assets/path6.png')} alt="Fondo1" className="headerImg bgOne" />
        <animated.img style={layoutProps} onLoad={() => imageLoaded(1)} src={isMobile ? require('./assets/path8.png') : !webP ? null : webP === 'webp' ? require('./assets/path8.webp') : require('./assets/path8.png')} alt="Fondo2" className="headerImg bgTwo" />
        <animated.img style={layoutProps} onLoad={() => imageLoaded(2)} src={isMobile ? require('./assets/path9.png') : !webP ? null : webP === 'webp' ? require('./assets/path9.webp') : require('./assets/path9.png')} alt="Fondo3" className="headerImg bgThree" />
        <animated.img style={layoutProps} onLoad={() => imageLoaded(3)} src={isMobile ? require(`${mobileImgSrc}`) : headerImg ? require(`${headerImg}`) : null} alt="Foto de la plaza de Azul" className="headerImg mainImg" />
        <div className="headerContainer" style={{ width: windowSize.width, height: windowSize.height }}>
          <div className="headerTextContainer">
            <SlidingText 
              text="Servicios jurídicos para empresas en toda la Provincia de Buenos Aires." 
              active={logoStage > 1} 
              fontFamily="BoutrosART"
              fontColor="#333"
              fontSize={!isMobile ? 1.7 : windowSize.width > 767 ? 2.4 : 4.3}
              width={!isMobile ? 35 : windowSize.width > 767 ? 45 : 70}
              margin={5}
            />
          </div>
        </div>
        <div className="servicesContainer">
          <ServiceBlock bgColor='#000' textColor="#f5f5f5" marginTop={0} marginBottom={windowSize.width < 768 ? 'calc(2vh)' : 'calc(6vh)'} title="Seguros" icon={insurance} text="Representamos a Compañías de Seguros en etapa prejudicial, mediación y juicios." />
          <ServiceBlock bgColor='rgba(192, 140, 137, 0.3)' textColor="#333" marginTop={windowSize.width < 768 ? 0 : 'calc(15vh)'} marginBottom={windowSize.width < 768 ? 'calc(2vh)' :  0} title="Consumidor" icon={consumer} text="Cubrimos audiencias ante todas las Oficinas de Defensa del Consumidor de la Provincia en forma presencial." />
          <ServiceBlock bgColor='#000' textColor="#f5f5f5" marginTop={0} marginBottom={windowSize.width < 768 ? 'calc(2vh)' : 'calc(20vh)'} title="ART" icon={art} text="Asistimos a Aseguradoras de Riesgos del Trabajo en todas las comisiones médicas de la Provincia." />
        </div>
        <div className="featureContainer">
          <div className="featureImgContainer">
            <animated.img style={firstFeatureImgProps} src={isMobile ? require('./assets/provincia.png') : !webP ? null : webP === 'webp' ? require('./assets/provincia.webp') : require('./assets/provincia.png')} alt="Provincia de Buenos Aires" className="featureImg" />
          </div>
          <div className="featureInfoContainer">
            <div className="featureTitle">
              <SlidingText 
                text="Cobertura en toda la Provincia" 
                active={firstFeature} 
                fontFamily="BoutrosART"
                fontColor="#333"
                fontSize={!isMobile ? 2.7 : windowSize.width < 768 ? 6 : 4}
                margin={7.5}
              />
            </div>
            <p className="featureText">
              Nuestro Estudio ha crecido junto con las necesidades de nuestros clientes.
              <br/><br/>
              Actualmente nos encontramos trabajando en todos los Departamentos Judiciales que comprenden el interior de la Provincia de Buenos Aires.
              <br/><br/>
              Representamos a Compañías de Seguros, ART y Empresas con gran caudal de asuntos relacionados al Derecho del Consumidor
            </p>
          </div>
        </div>
        <div className="featureContainer inverseFeatureContainer">
          <div className="featureInfoContainer">
            <div className="featureTitle">
              <SlidingText 
                text="Único Interlocutor" 
                active={secondFeature} 
                fontFamily="BoutrosART"
                fontColor="#333"
                fontSize={!isMobile ? 2.7 : windowSize.width < 768 ? 6 : 4}
                margin={7.5}
              />
            </div>
            <p className="featureText">
              La posibilidad de concentrar los temas en un único corresponsal.
              <br/><br/>
              Logrando de esta forma una auditoría más eficiente y centralizando la facturación a fin de evitar un dispendio innecesario de tiempo y recursos.
            </p>
          </div>
          <div className="featureImgContainer">
            <animated.img style={secondFeatureImgProps} src={isMobile ? require('./assets/interlocutor.png') : !webP ? null : webP === 'webp' ? require('./assets/interlocutor.webp') : require('./assets/interlocutor.png')} alt="Único interlocutor" className="featureImg" />
          </div>
        </div>
        <div className="featureContainer">
          <div className="featureImgContainer">
            <animated.img style={thirdFeatureImgProps} src={isMobile ? require('./assets/experiencia.png') : !webP ? null : webP === 'webp' ? require('./assets/experiencia.webp') : require('./assets/experiencia.png')} alt="Amplia Experiencia" className="featureImg" />
          </div>
          <div className="featureInfoContainer">
            <div className="featureTitle">
              <SlidingText 
                text="Amplia Experiencia" 
                active={thirdFeature} 
                fontFamily="BoutrosART"
                fontColor="#333"
                fontSize={!isMobile ? 2.7 : windowSize.width < 768 ? 6 : 4}
                margin={7.5}
              />
            </div>
            <p className="featureText">
              Más de cuarenta años de trayectoria avalan la idoneidad de nuestros profesionales.
              <br/><br/>
              La dinámica de trabajo de nuestro equipo combina experiencia y proactividad de abogados formados en estudios y empresas de primera línea.
            </p>
          </div>
        </div>
        <div className="featureContainer contactContainer">
          <div className="contactInfoContainer">
            <SlidingText 
              text="Contacto" 
              active={contactSliding} 
              fontFamily="BoutrosART"
              fontColor="#333"
              fontSize={!isMobile ? 4.5 : windowSize.width < 768 ? 10 : 6}
              margin={7.5}
            />
            <p className="contactEmailTitle">Email</p>
            <a className="contactEmail" href="mailto:contacto@estudio-rocca.com.ar" target="_blank" rel="noopener noreferrer">contacto@estudio-rocca.com.ar</a>
            <a className="contactLinkedInTitle" href="https://www.linkedin.com/company/estudio-rocca-abogados/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
          <div className="contactFormContainer">
            <div className="contactFormInner">
              <div className="formInputFullContainer">
                <FormTextInput placeholder="Nombre" value={name} onChange={(n) => setName(n)} />
              </div>
              <div className="formInputFullContainer">
                <FormTextInput placeholder="Email" value={email} onChange={(e) => setEmail(e)} />
              </div>
              <div className="formInputFullContainer">
                <FormTextArea placeholder="Mensaje" value={message} onChange={(m) => setMessage(m)} />
              </div>
              <AnimatedFormButton handleSubmit={handleSubmit} buttonText={buttonText} disabled={name === "" || email === "" || message === ""} />
            </div>
          </div>
        </div>
        <div className="footer">
          <p className="copyright">2020 © Estudio Rocca</p>
          <a href="https://www.estudioanima.com" target="_blank" rel="noopener noreferrer" className="animaLink">
            <img src={anima} alt="ANIMA Estudio Creativo" className="animaImg" />
          </a>
        </div>
      </animated.div>
    </div>
  );
}

export default App;
