import React from 'react';
import { useSpring, animated} from 'react-spring';

import './NavbarLink.css';

const NavbarLink = ({ text, stage, onClick }) => {

  const linkProps = useSpring({ color: stage === 2 ? '#fff' : '#333' })

  return (
    <span className="navbarLinkContainer" onClick={onClick}>
      <span className="navbarLinkFirst"><animated.span style={linkProps} className="navbarLinkText">{text}</animated.span></span>
      <span className="navbarLinkSecond"><animated.span style={linkProps} className="navbarLinkText">{text}</animated.span></span>
    </span>
  );
}

export { NavbarLink };