import React from 'react';
import { useSpring, animated, config } from 'react-spring';

import './SlidingText.css';

const SlidingText = ({ text, active, fontFamily, fontColor, fontSize, width, margin }) => {

  const slidingTextProps = useSpring({ transform: active ? 'translate(0, 0%)' : 'translate(0, 70%)', config: config.slow });

  return (
    <p className="slidingTextContainer" style={{ fontFamily: fontFamily, color: fontColor, fontSize: `${fontSize}vw`, lineHeight: `${fontSize * 1.6}vw`, width: width ? `${width}vw` : 'auto' }}>
      { text.split(" ").map( (item, index) => 
        <span key={index} className="slidingTextItem" style={{ marginRight: `${margin}px` }}>
          <animated.span style={slidingTextProps}>{item}</animated.span>
        </span> 
      )}
    </p>
  );
}

export { SlidingText };