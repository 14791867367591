import React from 'react';
import { ChevronDown } from 'react-feather';

import './ServiceBlock.css';

const ServiceBlock = ({ title, text, icon, bgColor, textColor, marginTop, marginBottom }) => {

  return (
    <div style={{ backgroundColor: bgColor, marginTop: marginTop, marginBottom: marginBottom }} className="serviceBlock">
      <img src={icon} alt={title} className="serviceBlockImg" />
      <p style={{ color: textColor }} className="serviceBlockTitle">{title}</p>
      <ChevronDown color={textColor} size={30} className="serviceBlockIcon" />
      <p style={{ color: textColor }} className="serviceBlockText">{text}</p>
    </div>
  );
}

export { ServiceBlock };